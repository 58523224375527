import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
// import './permission';
import router from './router';
import store from './store';
import { install } from '@/ui-plugin/elementUiPlugin';
import './components/autoInjectComp.ts';
import './assets/style/index.less';
import 'animate.css';

Vue.config.productionTip = false;
install(Vue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
