
































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Footer',
})
export default class SideButtons extends Vue {
  private timer=0;
  private timer1=0;
  private mobileFlag: any=false;
  private dialogVisible=false;
  mounted() {
    this._isMobile();
    const goTopBtn = this.$refs.goTop as HTMLElement;
    window.onscroll = function () {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      // 判断是否需要显示回滚按钮;
      if (scrollTop >= 200) {
        goTopBtn.style.display = 'flex';
      } else {
        goTopBtn.style.display = 'none';
      }
    };
    const kefuImg = this.$refs.kefuImg as HTMLElement;
    if (!this.mobileFlag) {
      kefuImg.style.display = 'block';
    } else {
      this.dialogVisible = true;
    }
    kefuImg.style.display = 'block';
    this.timer1 = setTimeout(() => {
      if (!this.mobileFlag) {
        kefuImg.style.display = 'none';
      } else {
        this.dialogVisible = false;
      }
    }, 3000);
  }
  goTop() {
    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      const top = scrollTop - 90 <= 0 ? 0 : scrollTop - 90;
      document.body.scrollTop = top;
      document.documentElement.scrollTop = top;
      if (top === 0) {
        this.timer && clearInterval(this.timer);
      }
    }, 1000 / 60);
  }
  // 判断是否是移动端
  _isMobile() {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) || false;
    this.mobileFlag = flag || false;
  }
  handleClose() {
    this.timer1 && clearInterval(this.timer1);
    this.dialogVisible = false;
  }
  openDialog() {
    this.dialogVisible = true;
  }
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
    this.timer1 && clearInterval(this.timer1);
  }
}
