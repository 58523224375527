import { render, staticRenderFns } from "./index.vue?vue&type=template&id=be9e2bcc&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=be9e2bcc&lang=less&rel=stylesheet%2Fless&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be9e2bcc",
  null
  
)

export default component.exports