import {
  Container,
  Main,
  Header,
  Footer,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownMenu,
  Button,
  DropdownItem,
  Dialog,
} from 'element-ui';
import { VueConstructor } from 'vue';

const components = [
  Container,
  Main,
  Header,
  Footer,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
  Dialog,
];

export const install = (vue: VueConstructor) => {
  components.forEach((c: any) => {
    vue.use(c);
  });
};
