












import { Component, Vue } from 'vue-property-decorator';
import AppMain from './components/AppMain/index.vue';
import Footer from './components/Footer/index.vue';
import NavBar from './components/NavBar/index.vue';
import SideButtons from './components/sideButtons/index.vue';

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Footer,
    NavBar,
    SideButtons,
  },
})
export default class Layout extends Vue {

}
