















































import { Component, Vue } from 'vue-property-decorator';

interface Partial{
  label: string;
  redirect?: string;
  path: string;
}
@Component({
  name: 'NavBar',
})
export default class NavBar extends Vue {
  private lists: Partial[] = [
    {
      label: '首页',
      path: '/home/index',
    },
    {
      label: '产品介绍',
      path: '/introduce/index',
    },
    {
      label: '战略合作',
      path: '/cooperation/index',
    },
    {
      label: '关于我们',
      path: '/about/index',
    },
  ];
  private scrollTop=0;
  private activeIndex=0;
  private navbaBeforeLeft=0;
  private erpLoginUrl=process.env.VUE_APP_ERP_URL;
  handItemEnter(index: number, animateFlag = true) {
    // let left = 0;
    const itemS = document.querySelectorAll('.menu-item');
    const beforeElement: any = document.querySelector('.narbar-hover-before') || {};
    if (!animateFlag) {
      beforeElement.style.transition = 'none';
    } else {
      beforeElement.style.transition = ' left 0.4s';
    }
    const widthS = Array.from(itemS).reduce((pre, item, cIndex) => {
      if (cIndex <= index) {
        pre += item.clientWidth;
      }
      return pre;
    }, 0);
    const targetWidth = itemS[index].clientWidth;
    this.navbaBeforeLeft = widthS - targetWidth / 2 - 20;
  }
  handItemLeave() {
    setTimeout(() => {
      this.handItemEnter(this.activeIndex);
    });
  }
  handScroll() {
    const top: number = document.body.scrollTop || document.documentElement.scrollTop;
    this.scrollTop = top;
  }

  mounted() {
    window.addEventListener('scroll', this.handScroll);
    this.activeIndex = this.lists.findIndex((item) => item.path === this.$route.path);
    this.handItemEnter(this.activeIndex, false);
    // debugger;
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.handScroll);
  }
  handGoRegister(): void {
    // const qd = sessionStorage.getItem('qd');
    // const cy = sessionStorage.getItem('cy');
    const url = process.env.VUE_APP_ERP_URL + '?isType=register';
    // if (qd && cy) {
    //   url += `&qd=${qd}&cy=${cy}`;
    // }
    window.location.href = url;
  }

  private todo(list: Partial, index: number): void {
    this.activeIndex = index;
    if (list.redirect) {
      window.location.href = list.redirect;
    }
    if (list.path) {
      this.$router.push(list.path);
    }
  }

}
