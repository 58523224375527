import Vue from 'vue';
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router';
import Layout from '@/Layout/index.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/home',
    component: Layout,
    name: '首页',
    children: [
      {
        path: 'index',
        name: 'home',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/HomeView/index.vue'),
      },
    ],
  },
  {
    path: '/cooperation',
    component: Layout,
    name: '合作',
    children: [
      {
        path: 'index',
        name: 'home',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/Cooperation/index.vue'),
      },
    ],
  },
  {
    path: '/introduce',
    component: Layout,
    name: '介绍',
    children: [
      {
        path: 'index',
        name: 'home',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/Introduce/index.vue'),
      },
    ],
  },
  {
    path: '/about',
    component: Layout,
    name: '关于我们',
    children: [
      {
        path: 'index',
        name: 'home',
        component: () => import(/* webpackChunkName: "HomeView" */ '@/views/About/index.vue'),
      },
    ],
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView/index.vue'),
  // },
  {
    path: '*',
    redirect: '/home/index',
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0, x: 0 }),
});
const originalPush = VueRouter.prototype.push as unknown as Promise<Route>;
VueRouter.prototype.push = function push(location: RawLocation) {
  return (originalPush as any).call(this, location).catch((err: Error) => err);
};

export default router;
